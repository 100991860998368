import RenderPageSections from 'src/components/cms/RenderedPageSections'
import Seo from 'src/components/seo/Seo'
import { getCMSPageByContentType } from 'src/utils/getCMSPageDataByContentType'

interface CmsData {
  sections: Array<{
    data: unknown
    id: string
    name: string
  }>
  status: string
}

interface Props {
  slug: string
  serverData: {
    cmsData?: CmsData
  }
}

export default function CampaignPage(props: Props) {
  const { serverData } = props
  const { cmsData } = serverData

  if (serverData === null) {
    return null
  }

  return (
    <>
      <Seo
        title=""
        description=""
        language=""
        canonical=""
        openGraph={{
          type: 'website',
          url: '',
          title: '',
          description: '',
        }}
      />

      <RenderPageSections sections={cmsData?.sections} />
    </>
  )
}

export const getServerData = async ({
  // query,
  params: { slug },
}: {
  query: Record<string, string>
  params: Record<string, string>
}) =>
  getCMSPageByContentType({
    slug,
    contentType: 'campanha',
  })
